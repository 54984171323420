import { useEffect, useState } from "react";
import UsersApi from "../../../api/usersApi";
import { VideoMetadata } from "../../../types/TypeSpecialist";
import Educations from "./Certificates/Educations";
import Federations from "./Certificates/Federations";
import Qualifications from "./Certificates/Qualifications";
import s from "./ShowDiff.module.css";
import url from "../../../utils/formApiUrl";
import { network } from "../../../config";

const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;

const { specialists } = network;

type ShowDiffProps = {
  oldProfile: Record<string, any>;
  newProfile: Record<string, any>;
  oldSpecialistData: Record<string, any>;
  newSpecialistData: Record<string, any>;
  userFieldsChanged: string[];
  specialistFieldsChanged: string[];
};

const keyLables: Record<string, any> = {
  name: "Name",
  surname: "Surname",
  latinName: "Latin name",
  latinSurname: "Latin surname",
  gender: "Gender",
  avatar: "Avatar",
  dateOfBirth: "Date of birth",
  location: "Location",
  briefDescription: "Brief description",
  mainSpecializations: "Main specialization",
  subSpecializations: "Sub specialization",
  practiceHours: "Practical hours",
  yearsOfExperience: "Years of experience",
  introductionVideo: "Intro video",
  educationCertificates: "Education certificates",
  federationCertificates: "Federation certificates",
  qualificationCertificates: "Qualification certificates",
  aboutMyself: "About myself",
  aboutTherapy: "How session go with me",
  contributeToProfession: "Contribute to profession",
  price: "Price per session",
  tags: "Tags",
  level: "Level",
  preferredLanguages: "Preferred languages",
};

const keysToSkip = ["labels", "_id", "relatedMainSpec", "__v"];

const skipForArrayIf = ["preferredLanguages"];

const ShowDiff = ({
  oldProfile,
  newProfile,
  oldSpecialistData,
  newSpecialistData,
  userFieldsChanged,
  specialistFieldsChanged,
}: ShowDiffProps) => {
  const token = localStorage.getItem("token");
  const [oldContent, setOldContent] = useState<React.ReactNode[]>([]);
  const [newContent, setNewContent] = useState<React.ReactNode[]>([]);

  const getTextWithKeys = (
    arrObj: Record<string, unknown>[] | undefined | null,
    isOldInfo: boolean
  ) => {
    if (arrObj === undefined || arrObj === null || !arrObj.length) {
      return <span>No Data</span>;
    }
    let result: React.ReactNode[] = [];
    arrObj.forEach((obj) => {
      result.push(
        <div className={isOldInfo ? s.oldValueTextBlock : s.newValueTextBlock}>
          {Object.entries(obj)
            .reverse()
            .map(([key, value], index) => {
              if (!keysToSkip.includes(key))
                return (
                  <div key={index}>
                    <span>{key + ": " + value}</span>
                  </div>
                );
            })}
        </div>
      );
    });
    return result.reverse();
  };

  function removeArrayDuplicates(
    oldArray: Record<string, unknown>[] | undefined,
    newArray: Record<string, unknown>[]
  ): {
    correctedOldArray?: Record<string, unknown>[];
    correctedNewArray: Record<string, unknown>[];
  } {
    if (!oldArray)
      return {
        correctedOldArray: oldArray,
        correctedNewArray: newArray,
      };
    const set1 = new Set(oldArray.map((item) => JSON.stringify(item)));
    const set2 = new Set(newArray.map((item) => JSON.stringify(item)));

    const filteredArr1 = oldArray.filter(
      (item) => !set2.has(JSON.stringify(item))
    );
    const filteredArr2 = newArray.filter(
      (item) => !set1.has(JSON.stringify(item))
    );

    return {
      correctedOldArray: filteredArr1,
      correctedNewArray: filteredArr2,
    };
  }
  const getUniqueIds = (
    oldVideos: { video: VideoMetadata; language: string }[],
    newVideos: { video: VideoMetadata; language: string }[]
  ): { oldVideosIds: string[]; newVideosIds: string[] } => {
    const ids1 = oldVideos.map((item) => item.video._id);
    const ids2 = newVideos.map((item) => item.video._id);

    // Ищем уникальные элементы в первом массиве
    const oldVideosIds = ids1.filter((id) => !ids2.includes(id));
    // Ищем уникальные элементы во втором массиве
    const newVideosIds = ids2.filter((id) => !ids1.includes(id));

    return { oldVideosIds, newVideosIds };
  };
  const prepareData = async () => {
    if (!token) return;
    userFieldsChanged.forEach(async (item, index) => {
      if (item === "avatar") {
        const responseOld = await UsersApi.getUserPublicAvatarAndName(
          newProfile._id
        );

        const responseNew = await UsersApi.getUserAvatarAndName(
          token,
          newProfile._id
        );
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <img src={responseOld.avatar} className={s.avatar} />{" "}
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <img src={responseNew.avatar} className={s.avatar} />{" "}
          </div>,
        ]);
      } else if (item === "preferredLanguages") {
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            {oldProfile.preferredLanguages.map(
              (oldItem: string, index: number) => (
                <span className={s.oldValue} key={index}>
                  {oldItem}
                </span>
              )
            )}
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            {newProfile.preferredLanguages.map(
              (newItemn: string, index: number) => (
                <span className={s.newValue} key={index}>
                  {newItemn}
                </span>
              )
            )}
          </div>,
        ]);
      } else if (
        Array.isArray(newProfile[item]) &&
        !skipForArrayIf.includes(item)
      ) {
        const { correctedOldArray, correctedNewArray } = removeArrayDuplicates(
          oldProfile[item],
          newProfile[item]
        );

        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.oldValue}>
              {getTextWithKeys(correctedOldArray, true)}
            </span>
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.newValue}>
              {getTextWithKeys(correctedNewArray, false)}
            </span>
          </div>,
        ]);
      } else {
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.oldValueTextBlock}>{oldProfile[item]}</span>
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.newValueTextBlock}>{newProfile[item]}</span>
          </div>,
        ]);
      }
    });
  };
  const prepareSpecialistData = async () => {
    if (!token) return;
    specialistFieldsChanged.forEach(async (item, index) => {
      if (item === "level") {
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.oldValueTextBlock}>
              {oldSpecialistData[item].level}
            </span>
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.newValueTextBlock}>
              {newSpecialistData[item].level}
            </span>
          </div>,
        ]);
      } else if (item === "introductionVideo") {
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            {getUniqueIds(
              oldSpecialistData.introductionVideo,
              newSpecialistData.introductionVideo
            ).oldVideosIds.map((item) => (
              <video
                src={url(
                  `${connectionString}specialists/`,
                  specialists.getSpecialistIntroVideo,
                  {
                    id: oldProfile._id,
                    videoId: item,
                  }
                )}
                className={s.video}
                crossOrigin="anonymous"
                controls
                key={item}
              />
            ))}
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            {getUniqueIds(
              oldSpecialistData.introductionVideo,
              newSpecialistData.introductionVideo
            ).newVideosIds.map((item) => (
              <video
                src={url(
                  `${connectionString}specialists/`,
                  specialists.getSpecialistIntroVideo,
                  {
                    id: newProfile._id,
                    videoId: item,
                  }
                )}
                className={s.video}
                crossOrigin="anonymous"
                controls
                key={item}
              />
            ))}
          </div>,
        ]);
      } else if (item === "federationCertificates") {
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <Federations isOldProfile specialistId={newSpecialistData._id} />
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <Federations
              isOldProfile={false}
              specialistId={newSpecialistData._id}
            />
          </div>,
        ]);
      } else if (item === "qualificationCertificates") {
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <Qualifications isOldProfile specialistId={newSpecialistData._id} />
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <Qualifications
              isOldProfile={false}
              specialistId={newSpecialistData._id}
            />
          </div>,
        ]);
      } else if (item === "educationCertificates") {
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <Educations isOldProfile specialistId={newSpecialistData._id} />
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <Educations
              isOldProfile={false}
              specialistId={newSpecialistData._id}
            />
          </div>,
        ]);
      } else if (Array.isArray(newSpecialistData[item])) {
        const { correctedOldArray, correctedNewArray } = removeArrayDuplicates(
          oldSpecialistData[item],
          newSpecialistData[item]
        );
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.oldValue}>
              {getTextWithKeys(correctedOldArray, true)}
            </span>
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.newValue}>
              {getTextWithKeys(correctedNewArray, false)}
            </span>
          </div>,
        ]);
      } else {
        setOldContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.oldValueTextBlock}>
              {oldSpecialistData[item]}
            </span>
          </div>,
        ]);
        setNewContent((prev) => [
          ...prev,
          <div className={s.diffElement}>
            <span style={{ color: "white" }}>{keyLables[item]}</span>
            <span className={s.newValueTextBlock}>
              {newSpecialistData[item]}
            </span>
          </div>,
        ]);
      }
    });
  };

  useEffect(() => {
    (async () => {
      await prepareData();
      await prepareSpecialistData();
    })();
  }, [userFieldsChanged]);

  return (
    <div className={s.container}>
      {oldContent.map((item, index) => (
        <div key={index} className={s.oldInfo}>
          <div className={s.columnBlock}>{item}</div>
          <div className={s.columnBlock}>{newContent[index]}</div>
        </div>
      ))}
    </div>
  );
};

export default ShowDiff;
