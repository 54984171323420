import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { StatisticResponse } from "../types/Statistic";
import url from "../utils/formApiUrl";
import customKy from "./kyExtend";

const { stats } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "statistics/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class StatisticApi {
  static async getAdminDashboardStats(token: string): Promise<
    TypeDefaultResponse & {
      statistics: StatisticResponse;
    }
  > {
    return await customKy
      .get(url(baseURL, stats.getAdminDashboardStats), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
