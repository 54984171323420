import ky from "ky";
import { network } from "../config";
import {
  MyProjectType,
  Project,
  Roles,
  TypeCompanyEmployee,
} from "../types/Company";
import { TypeNewProject } from "../types/Projects";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { TypeUpdatedUserInfo } from "../types/TypeUsers";
import customKy from "./kyExtend";
import url from "../utils/formApiUrl";

const { projects } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "projects/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class ProjectApi {
  static async createProject(
    token: string,
    payload: { companyId: string; newProject: TypeNewProject }
  ): Promise<TypeDefaultResponse & { project: Project }> {
    return await customKy
      .post(url(baseURL, projects.createProject), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async update(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { project: Project }> {
    return await customKy
      .patch(url(baseURL, projects.update, { id: updateData._id }), {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async deleteProject(
    token: string,
    projectId: string
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .delete(url(baseURL, projects.deleteProject, { id: projectId }), {
        headers: getAuthHeader(token),
        json: { projectId },
      })
      .then((res) => res.json());
  }

  static async inviteUserToProject(
    token: string,
    payload: {
      emails: string[];
      projectId: string;
      role: Roles.DEEFAULT_USER | Roles.SPECIALIST;
    }
  ): Promise<TypeDefaultResponse[]> {
    return await customKy
      .post(
        url(baseURL, projects.inviteUserToProject, { id: payload.projectId }),
        {
          headers: getAuthHeader(token),
          json: payload,
        }
      )
      .then((res) => res.json());
  }

  static async addSpecialistsToProject(
    token: string,
    specialistIds: string[],
    projectId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(url(baseURL, projects.addSpecialistsToProject, { id: projectId }), {
        headers: getAuthHeader(token),
        json: { specialistIds },
      })
      .then((res) => res.json());
  }

  static async removeUserFromProject(
    token: string,
    payload: { userIdToRemove: string; projectId: string }
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(
        url(baseURL, projects.removeUserFromProject, { id: payload.projectId }),
        {
          headers: getAuthHeader(token),
          json: payload,
        }
      )
      .then((res) => res.json());
  }

  static async getProjectParticipants(
    token: string,
    projectId: string
  ): Promise<
    TypeDefaultResponse & { participants?: TypeCompanyEmployee[] | null }
  > {
    return await customKy
      .get(url(baseURL, projects.getProjectParticipants, { id: projectId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async uploadVideoToProject(
    token: string,
    formData: FormData
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(url(baseURL, projects.uploadVideoToProject), {
        headers: getAuthHeader(token),
        body: formData,
        timeout: 30000,
      })
      .then((res) => res.json());
  }
}
