import ky from "ky";
import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { RefoundSession } from "../types/TypeSession";
import customKy from "./kyExtend";
import url from "../utils/formApiUrl";

const { sessions } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "sessions/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SessionsApi {
  static async getSessionsWaitingForRefund(
    token: string,
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      sessions?: RefoundSession[];
      amount: number;
    }
  > {
    return await ky
      .get(
        url(baseURL, sessions.getSessionsWaitingForRefund, { page, limit }),
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async refundSession(
    token: string,
    payload: {
      userId: string;
      sessionId: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(url(baseURL, sessions.refundSession), {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async payOutSession(
    token: string,
    payload: {
      userId: string;
      sessionId: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(url(baseURL, sessions.payOutSession), {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
