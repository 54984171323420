import ky from "ky";
import { network } from "../config";
import {
  DialogChat,
  SendDialogMessagePayload,
  TypeDialogMessage,
} from "../types/DialogChat";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { MessageFile, TypeMessage } from "../types/TypeMessageChat";
import socket from "../utils/socket";
import url from "../utils/formApiUrl";

const { chat } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "dialog-chats/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class DialogChatAPI {
  static async createDialogChat(
    token: string,
    payload: {
      userId: string;
      userIdToSend: string;
    }
  ): Promise<TypeDefaultResponse & { chat?: DialogChat }> {
    return await ky
      .post(url(baseURL, chat.createDialogChat), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getDialogMessagesById(
    token: string,
    chatId: string,
    limit: number,
    page: number,
    options?: RequestInit
  ): Promise<
    TypeDefaultResponse & {
      messages: TypeDialogMessage[];
    }
  > {
    return await ky
      .get(
        url(baseURL, chat.getDialogMessagesById, { id: chatId, page, limit }),
        {
          headers: getAuthHeader(token),
          ...options,
        }
      )
      .then((res) => res.json());
  }

  static async getDialogMessageById(
    token: string,
    messageId: string
  ): Promise<
    TypeDefaultResponse & {
      responseMessage?: TypeDialogMessage;
    }
  > {
    return await ky
      .get(url(baseURL, chat.getDialogMessageById, { id: messageId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async getMessageFiles(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & {
      files: MessageFile[];
    }
  > {
    return await ky
      .get(url(baseURL, chat.getMessageFiles, { id }), {
        headers: getAuthHeader(token),
        timeout: 30000,
      })
      .then((res) => res.json());
  }

  static async sendMessage(payload: SendDialogMessagePayload): Promise<
    TypeDefaultResponse & {
      newMessage?: TypeMessage | null;
    }
  > {
    return new Promise((resolve) => {
      socket.emit(chat.sendMessageToDialogChat, payload);
      socket.once(chat.onDialogMessage, (response) => {
        resolve(response);
      });
    });
  }

  static async markMessagesAsRead(
    token: string,
    messageIds: string[]
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(url(baseURL, chat.markMessagesAsRead), {
        headers: getAuthHeader(token),
        json: { messageIds },
      })
      .then((res) => res.json());
  }

  static async getUnreadDialogMessagesLength(token: string): Promise<
    TypeDefaultResponse & {
      amount: number;
    }
  > {
    return await ky
      .get(url(baseURL, chat.getUnreadDialogMessagesLength), {
        headers: getAuthHeader(token),
        timeout: 30000,
      })
      .then((res) => res.json());
  }
}
