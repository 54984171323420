import ky from "ky";
import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import {
  MessageFile,
  TypeMessage,
  TypeSendMessagePayload,
  TypeSessionChatResponse,
} from "../types/TypeMessageChat";
import socket from "../utils/socket";
import customKy from "./kyExtend";
import url from "../utils/formApiUrl";

const { chat } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "sessions/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class MessageChatApi {
  static async sendMessage(payload: TypeSendMessagePayload): Promise<
    TypeDefaultResponse & {
      newMessage?: TypeMessage | null;
    }
  > {
    return new Promise((resolve) => {
      socket.emit(chat.sendMessage, payload);
      socket.once(chat.onMessage, (response) => {
        resolve(response);
      });
    });
  }

  static async getMessageFiles(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & {
      files: MessageFile[];
    }
  > {
    return await customKy
      .get(url(baseURL, chat.getMessageFiles, { id }), {
        headers: getAuthHeader(token),
        timeout: 30000,
      })
      .then((res) => res.json());
  }
}
