export const network = {
  users: {
    getUserById: "id/:id",

    addAbilitiesToUser: "abilities",

    register: "register",

    sendPhoneConfirmation: "phone/confirm/code/send",

    confirmPhoneNumberWithCode: "phone/confirm",

    resendConfCode: "phone/confirm/code/resend",

    confirmRegister: "register/confirm",

    auth: "auth",
    logout: "logout",

    refreshAccessToken: "tokens/refresh",

    getUserByToken: "token/:token",

    getUserSelectedFields: ":id/fields/:userFields/:specFields",

    update: ":id/update",

    updatePassword: "password",

    confirmPassUpdate: "password/update/confirm",

    getUserPublicAvatarAndName: ":id/public/avatar-name",

    getUserAvatarAndName: ":id/avatar-name",

    getAvatarsOfSessionMembers: "avatars/session",

    getUserProfileInfoById: ":id/profile/info",

    checkIfUserJoinedAnyCompany: ":id/joined/company",

    getAllNonSpecialistUsers: "non-specialists/:page/:limit",

    createNewGender: "gender",
    getAllGenders: "genders",

    getUserPublicInfoById: ":id/public/profile/info/:language",

    getUserPayments: ":id/payments/:page/:limit",

    updateAvatar: ":id/avatar",

    getAvatarStream: "avatar/:id/stream",

    fixUserAvatars: "avatars/fix",

    fixScheduleType: "schedule/fix",

    findUserBySearchString: "search/:string/:specialistOnly",

    getUserLastActivityLogs: ":id/activity/logs",

    addFavouriteUser: "favourites",

    removeFavouriteUser: "favourites",

    getUsersFavouritesList: ":id/favourites/:page/:limit",

    checkIfUserWorkedWithSpecialist: "worked/specialist/:specialistUserId",

    checkIfUserIsFavourite: "is-favourite/:id",

    sendEmailConfirmation: "email/confirm/code/send",
    confirmEmailWithCode: "email/confirm",

    getUserPublicInfoLatinName:
      ":id/:name/:surname/public/profile/info/:language",

    getUserGeoInfo: "geo/info",

    getFilteredClients: "clients/filtered",

    isTwilioAvailable: "twilio/available",

    connectPhoneNumber: "phone/connect",

    getUsersBookings: ":id/bookings",

    getUsersBookedPackages: ":id/packages/booked",

    getUsersSessionPayments: ":id/sessions/payments/:page/:limit",

    getUsersSessionHistory: ":id/sessions/history/:page/:limit",

    getUserSessionsPagination: ":id/sessions/:page/:limit",

    getUsersDialogChats: ":id/dialog-chats/:page/:limit",

    getUserNotes: ":id/notes/:page/:limit",

    getUserTodos: ":id/todos/lists/:page/:limit/:sortType/:sortField",

    getCompaniesOfUser: ":id/companies",

    getUserProjects: ":id/:page/:limit",

    getUserPosts: ":id/posts/:page/:limit",
  },

  sessions: {
    incomingCall: "incomingCall",
    acceptCall: "acceptCall",

    getMyId: "getMyId",

    allUsers: "allUsers",

    callUser: "callUser",
    callAccepted: "callAccepted",

    cameraDisconnected: "cameraDisconnected",

    joinRoom: "joinRoom",
    onJoinRoom: "onJoinRoom",

    disconnectFromSession: "disconnectFromSession",

    createSession: "",

    getSessionById: ":id",

    bookSession: "bookings/book",
    bookingNotify: "bookingNotify",

    acceptBooking: "bookings/:id/accept",
    bookingAcceptNotify: "bookingAcceptNotify",

    getBookingById: "bookings/:id",

    rescheduleBooking: "bookings/:id/reschedule",
    rescheduleBookingNotify: "rescheduleBookingNotify",

    rescheduleSession: ":id/reschedule",
    rescheduleSessionNotify: "rescheduleSessionNotify",

    getAllUserSessions: "all/:id/:startDate/:endDate",

    userActionInSessionChat: "userActionInSessionChat",

    commentSession: ":id/comment",

    getSessionVisitDuration: ":id/visit-durations",

    checkIfCanLeaveComment: ":id/can-comment",

    cancelBooking: "bookings/:id/cancel",

    createNewDefaultComment: "comments/default",
    getAllDefaultComments: "comments/all/default",

    createSessionPackage: "packages",

    updatePackage: "packages/:id",

    setSessionPackagePreviewFile: "packages/:id/preview-file",

    setFileToPackageSession: "packages/:id/session/preview-file",

    deletePackageSessionFile: "packages/:id/session/preview-file",

    getPackageFiles: "packages/:id/files",

    deletePackage: "packages/:id",

    getPackageById: "getPackageById",

    getBookedPackageById: "getBookedPackageById",

    bookSessionPackage: "packages/book",

    acceptPackageBooking: "package/:id/accept",

    cancelPackageBooking: "package/:id/cancel",

    getAllSessionPayments: "payments/:page/:limit",

    createPackagePromocode: "packages/:id/promocodes",

    getAllPackagePromocodes: "packages/promocodes/all",

    getPackageImageStream: "packages/images/stream/:id",

    getPackageVideoStream: "packages/videos/stream/:id",

    getPackageSessionImageStream: "packages/sessions/images/stream/:id",

    checkIfCanBookPackage: "packages/:id/can-book/:userId",

    initCronForSession: "initCronForSession",

    refundSession: "refund",
    payOutSession: "pay-out",

    getSessionsWaitingForRefund: "refundable/:page/:limit",

    getSessionInfoById: ":id/info",

    getTotalBookingsAmount: "bookings/amount/total",

    allowAudioRecordForSpecialist: ":id/audio-record/allow",

    sendRealTimeSessionRequest: "real-time/book",
    checkIfCanBookRealTimeSession: "real-time/can-book/:userId/:specId",
    acceptRealTimeSessionRequest: "real-time/accept",
    cancelRealTimeSessionRequest: "real-time/cancel",

    getRealTimeBookingById: "bookings/real-time/:id",

    connectSpecialist: "connectSpecialist",

    addConnectedSpecialist: "addConnectedSpecialist",

    disconnectSpecialist: "disconnectSpecialist",
  },

  chat: {
    joinChat: "joinChat",
    onJoinChat: "onJoinChat",

    sendMessage: "sendMessageInChat",
    onMessage: "onMessageInChat",

    getChat: ":id/chat",

    newMessage: "receiveNewMessage",

    joinDialogChat: "joinDialogChat",

    sendMessageToDialogChat: "sendMessageToDialogChat",
    onDialogMessage: "onDialogMessage",

    newDialogMessage: "newDialogMessage",

    createDialogChat: "",

    getMessageFiles: "chat/message/:id/files",

    getDialogMessagesById: ":id/messages/:page/:limit",

    getDialogChatById: "getDialogChatById",

    getMessageById: "getMessageById",

    markMessagesAsRead: "messages/read",

    addSystemMessageToDialogChat: "addSystemMessageToDialogChat",

    getSessionChatMessages: ":id/chat/messages/:page/:limit",

    getDialogMessageById: "messages/:id",

    getUnreadDialogMessagesLength: "messages/unread/length",

    getMessageFileStream: "messages/files/:id/stream",

    getMessageImages: "messages/:id/images",

    downloadMessageFile: "messages/files/:id/download",
  },

  specialists: {
    update: ":id",

    uploadIntroVideo: ":id/intro-video",
    uploadIntroVideoToBucket: "intro-video",
    finishUploadIntroVideoToBucket: "intro-video/complete",

    uploadEducationCertificates: ":id/certificates/educations",
    removeEducationCertificate: ":id/certificates/educations",

    uploadQualificationCertificates: ":id/certificates/qualifications",
    removeQualificationCertificate: ":id/certificates/qualifications",

    uploadFederationCertificates: ":id/certificates/federations",
    removeFederationCertificate: ":id/certificates/federations",

    getSpecialistById: "id/:id",

    getSpecialistIntroVideo: ":id/intro-video/:videoId",

    getEducationCertificates: ":id/certificates/educations/:page/:limit",
    getEducationPublicCertificates:
      ":id/public/certificates/educations/:page/:limit",

    getQualificationCertificates:
      ":id/certificates/qualifications/:page/:limit",
    getQualificationPublicCertificates:
      ":id/public/certificates/qualifications/:page/:limit",

    getFederationCertificates: ":id/certificates/federations/:page/:limit",
    getFederationPublicCertificates:
      ":id/public/certificates/federations/:page/:limit",

    getRecommendedSpecialists: "recommended",

    getFilteredSpecialists: "filtered",

    getFilterAvailableParams: "filters/params",

    createMainSpecialization: "main-specs",

    createSubSpecialization: "sub-specs",

    getMainSpecializations: "main-specs",

    getSubSpecializations: "main-specs/:ids/sub-specs",

    getAvailableParams: "params",

    getSpecialistFreeTime: ":userId/:id/free-time/:language",

    getAllSpecialists: "all/:page/:limit",

    getNonVerifiedSpecialists: "non-verified/:page/:limit",

    inviteSpecialistToApp: "invite",

    bulkInviteSpecialistToApp: "invite/bulk",

    getProfileChangeDiff: ":id/profile-diff",

    getAllSpecialzations: "specializations/all",

    createSpecialistTag: "tags",

    changeSpecialistTag: "tags",

    deleteSpecialistTag: "tags",

    addTagsToSpecialist: ":id/tags",

    removeTagsFromSpecialist: ":id/tags",

    getSpecialistTags: "tags/query",

    getEducationCertificateStream: "certificates/educations/stream/:id",
    getQualificationCertificateStream: "certificates/qualifications/stream/:id",
    getFederationCertificateStream: "certificates/federations/stream/:id",
    getFederationBadgeStream: "certificates/federations/badges/stream/:id",

    addNewSpecialistProfileToUser: ":id/profile",

    createFederationBadge: "federations/badges",
    createEducation: "educations",
    createFederation: "federations",
    createQualification: "qualifications",

    updateFederationBadge: "federations/badges",
    updateEducation: "educations",
    updateFederation: "federations",
    updateQualification: "qualifications",

    getFederationBadges: "federations/badges/:specializationId",
    getEducations: "educations/:specializationId",
    getFederations: "federations/:specializationId",
    getQualifications: "qualifications/:specializationId",

    getAllSpecialistBadges: ":id/federations/badges",
    getAllSpecialistUserBadges: "users/:id/federations/badges",

    getAllSpecialistLevels: "levels",

    createEducationCourse: "education/course",
    createEducationAccreditation: "education/accreditation",

    getEducationCourses: "educations/courses/query",
    getEducationAccreditations: "educations/accreditations/query",

    updateEducationCourse: "educations/courses",
    updateEducationAccreditation: "educations/accreditations",

    createTaxCategory: "tax-categories",
    updateTaxCategory: "tax-categories",
    deleteTaxCategory: "tax-categories",
    getTaxCategories: "tax-categories",
    setSpecialistTaxCategory: "tax-categories",

    getAllEducations: "educations/query",
    getAllEducationCourses: "educations/courses/query",
    getAllEducationAccreditations: "educations/accreditations/query",
    getAllFederations: "federations/query",
    getAllQualifications: "qualifications/query",

    getSpecialistServicesConfig: ":id/services-configs",

    getSpecialistPricing: ":id/pricing",

    addNextLevelPriceRotation: "levels/price-rotations/next",
    getNextPriceRotation: "levels/price-rotations/next",
    getCurrentPriceRotation: "levels/price-rotations/current",
    getPriceRotationFile: "levels/price-rotations/file/:id",

    getSpecialistsComments: ":id/comments/:page/:limit",
    getSpecialistsSessionsAmount: ":id/sessions/amount",

    getSpecialistSessionPackages: ":id/sessions/packages",

    getSpecialistSessionPackageById: "sessions/packages/:id",

    getGeneralPackagesInfo: ":id/packages/stats",
  },

  stripe: {
    createPayment: "payment",

    createPaymentForSession: "session/payment",

    createPaymentForSubscription: "subscription/payment",

    confirmPaymentSucceeded: "confirmPaymentSucceeded",

    confirmSubscriptionSucceeded: "confirmSubscriptionSucceeded",

    cancelSubscription: "cancelSubscription",

    subscriptionRenewalEvent: "subscriptionRenewalEvent",

    getAllPayments: "payments/:page/:limit",

    createDepositPromocode: "deposits/promocodes",

    getAllDepositPromocodes: "deposits/promocodes",

    handleStripeWebhook: "webhooks/handle",

    payDraftInvoice: "payDraftInvoice",

    savePayoutInfo: "payout-info",
    getPayoutInfo: "payout-info",
  },

  notes: {
    createNote: "",

    update: ":id",

    deleteNote: ":id",

    getNoteById: "getNoteById",
  },

  todos: {
    createTodo: "lists/:id/todos",

    updateTodoList: "lists/:id",
    update: "lists/:id/todos",

    deleteTodoList: "lists/:id",
    deleteTodo: "lists/:id/todos",

    createTodoList: "lists",

    getTodoById: "getTodoById",

    getTodoListById: "getTodoListById",

    createTodoStatus: "statuses",
  },

  notifies: {
    createNotify: "",

    joinNotifyChannel: "joinNotifyChannel",

    sendNotify: "sendNotify",

    sendPassChangeConfirmation: "sendPassChangeConfirmation",
    sendRegisterConfirmation: "sendRegisterConfirmation",

    getUnreadNotifies: "unread/user/:id",

    markNotifyAsRead: "read",

    getNotifyById: "getNotifyById",

    sendInviteToCompany: "sendInviteToCompany",

    sendInviteToProject: "sendInviteToProject",

    sessionCancelNotify: "sessionCancelNotify",

    emailAboutCompanyEvent: "emailAboutCompanyEvent",

    emailAboutProjectKick: "emailAboutProjectKick",

    sendInviteToApp: "sendInviteToApp",

    emailAboutIncommingBooking: "emailAboutIncommingBooking",

    emailHourBeforeSession: "emailHourBeforeSession",

    emailAboutPackageBooking: "emailAboutPackageBooking",

    sendMobileNotify: "mobile",

    emailAboutSubscriptionDeactivated: "emailAboutSubscriptionDeactivated",

    sendEmailConfirmationCode: "sendEmailConfirmationCode",

    sendNotifyAboutProfileAbandon: "sendNotifyAboutProfileAbandon",
  },

  companies: {
    createCompany: "",

    update: ":id",

    deleteCompany: ":id",

    getCompanyById: ":id",

    getCompanyParticipants: ":id/participants",

    getCompanyStatistic: ":id/stats",

    inviteUserToCompany: ":id/invite",

    inviteBulkUsersToCompany: ":id/invite/bulk",

    joinCompany: "join",

    getCompanySessions: ":id/sessions",

    getCompanyProjects: ":id/projects",

    removeUserFromCompany: ":id/users/remove",

    changeCompanyUserRole: ":id/users/roles",

    getAllCompanies: ":page/:limit",

    generateEntryCodeWithoutEmail: ":id/entry-code/generate",

    getCompanySpecialists: ":id/specialists/all",
  },

  projects: {
    createProject: "",

    update: ":id",

    deleteProject: ":id",

    inviteUserToProject: ":id/invite",

    addSpecialistsToProject: ":id/specialists/add",

    joinProject: "private/join",

    joinNonPrivateProject: "public/join",

    getProjectById: ":id",

    removeUserFromProject: ":id/users/remove",

    getProjectParticipants: ":id/participants",

    getProjectVideoStream: "video/:id/stream",

    getFilteredProjectSpecialists: "specialists/filtered",

    getAvailableSessionsInProject: ":id/sessions/stats",

    uploadVideoToProject: "video",

    deleteVideoFromProject: "video",

    getMyProjectsWithoutPackage: "no-packages/:specId",

    getProjectPackageOfSpecialist: ":id/packages/:specId",
  },

  google: {
    generateCode: "auth/url",
    redirect: "redirect",

    insertNewEvent: "insertNewEvent",

    checkGoogleRefreshToken: "token/refresh",
  },

  stats: {
    getAdminDashboardStats: "admin/dashboard",
  },

  posts: {
    createPost: "",

    createAdminPost: "admin",

    deletePost: ":id",

    getPostById: "getPostById",

    getPostCommentById: "getPostCommentById",

    updatePost: ":id",

    updatePostFiles: ":id/files",

    removePostFile: ":id/files",

    getFollowingPosts: "following/:id/:page/:limit",

    likePost: "like",

    unlikePost: "unlike",

    commentPost: "comment",

    updateComment: "comments/:id",

    removeComment: "comments/:id",

    likeComment: "comments/like",

    unlikeComment: "comments/unlike",

    getPostFiles: ":id/files",

    getPostImageStream: "files/images/:id",

    getPostVideoStream: "files/videos/:id",

    getUnverifiedPosts: "unverified/:page/:limit",
  },

  socials: {
    followUser: "follow",

    unfollowUser: "unfollow",

    getFollowers: "followers/:page/:limit",

    getFollowings: "followings/:page/:limit",

    checkIfFollowed: "followed/:id",
  },

  subscriptions: {
    createSubscription: "",
    updateSubscription: "",
    deleteSubscription: "",
    getAllSubscriptions: "query",
    getActiveSubscribersAmount: ":id/users/active/amount",
    getMyActiveSubscription: "current",
    getMySubscriptionHistory: "history",
    createSubscriptionShortInfo: ":id/short-info",
    createSubscriptionFeatures: "short-info/:id/features",
    getSubscriptionIconStream: "icons/:id/stream",
    updateSubscriptionShortInfo: "short-info",
    updateSubscriptionFeature: "short-info/features",
    deleteSubscriptionShortInfo: "short-info",
    deleteSubscriptionFeature: "short-info/features",
    updateSubscriptionShortInfoIcon: "short-info/icon",
    updateSubscriptionFeatureIcon: "short-info/features/icon",
    getTotalSubscriptionsAmount: "amount",
  },

  treeQuizes: {
    createTreeQuiz: "",
    addTreeQuizFirstQuestion: "questions/first",
    getTreeQuiz: "id/:id",
    createTreeQuizQuestion: "questions",
    addAnswerToTreeQuestion: "questions/answers",
    removeAnswerFromTreeQuestion: "questions/answers",
    getTreeQuizQuestion: "questions/:id",
    createTreeQuizAnswer: "questions/answers",
    changeTreeQuizAnswer: "questions/answers",
    getTreeQuizAnswer: "questions/answers/:id",
    saveTreeQuizAnswerResult: "answer",
    cancelTreeQuizAnswerResult: "answer",
    createTreeQuizResult: "results",
    addAnswerResultToTreeQuizResult: "results/add/answer",
    getTreeQuizResult: "results",
    getRecommendationsFromTreeQuiz: "recommendations/:page/:limit",
    getRecommendationQuiz: "recommendation",
    buildQuizes: "build",
    buildTestSpecialists: "speciallists/build",
  },

  twilio: {
    sendRegisterVerificationCode: "sendRegisterVerificationCode",
    verifyRegisterConfirmationCode: "verifyRegisterConfirmationCode",
  },

  deployFixes: {
    specialistIdToSpecialistIds: "specialistIdToSpecialistIds",

    specialistUsersToSpecialists: "specialistUsersToSpecialists",

    addLatinNameToUsers: "addLatinNameToUsers",

    scheduleReworkFix: "scheduleReworkFix",

    fixNamesMultilanguage: "fixNamesMultilanguage",
    fixIntroVideosMultilanguage: "fixIntroVideosMultilanguage",

    addServicesConfigToSpecialists: "addServicesConfigToSpecialists",
  },

  recordings: {
    approveRecording: "approve/:id",

    callAudioRecorder: "callAudioRecorder",

    getAudioRecordStream: "stream/:id",

    getSessionRecordingInfo: "info/:id",

    recordStarted: "recordStarted",
    recordStopped: "recordStopped",
    recordInitialized: "recordInitialized",
    recordCancel: "recordCancel",
  },

  databasePatches: {
    importEducations: "educations",
    importFederations: "federations",
    importQualifications: "qualifications",
  },

  socketEvents: {
    incomingBooking: "incomingBooking",
    canceledBooking: "canceledBooking",
    acceptedBooking: "acceptedBooking",
  },
};

export const googleScopes = [
  "https://www.googleapis.com/auth/calendar",
  "https://www.googleapis.com/auth/userinfo.email",
];

export const constants = {
  questionAboutMainSpecialization: "Which specialist would you like?",
  questionAboutSubSpecialization: "Select specialist subspecialty",
  questionAboutGender: "Select preffered gender of specialist",
  coachSubSpecQuestion: "Coach quiz",
  mentorSubSpecQuestion: "Mentor quiz",
  psychologistSubSpecQuestion: "Psyhologist quiz",
  coachSubSpecName: "Coach",
  mentorSubSpecName: "Mentor",
  psychologistSubSpecName: "Psychologist",
};

export const emailTexts = {
  projectKickSubject: "Project kick",
  googleCalendarSubject: "Google Calendar",
  sessionCancelSubject: "Canceled session",
  projectInviteSubject: "Invitation to project",
  companyInviteSubject: "Invitation to company",
  registerConfirmSubject: "Confirm Registration",
  passwordUpdateSubject: "Password update",
  appInviteSubject: "Invite to Talzi!",
  googleCalendarConnectTitle:
    "You have not connected your Google Calendar to Talzi. Please, do this, to see your sessions in your google calendar!",
  incommingBookingSubject: "Session booking",
  incommingPackageBookingSubject: "Session package booking",
  hourBeforeSession: "Incoming session",
  subscription: "Talzi subscription",
  profileAbandoned: "Profile abandoned",
};
