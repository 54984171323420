import ky from "ky";
import { network } from "../config";
import {
  GetPostFilesResposne,
  Post,
  PostComments,
  PostFileTypeToUpdate,
} from "../types/Posts";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import customKy from "./kyExtend";
import url from "../utils/formApiUrl";

const { posts } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "posts/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class PostApi {
  static async deletePost(
    token: string,
    postId: string
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .delete(url(baseURL, posts.deletePost, { id: postId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async updatePost(
    token: string,
    updateData: Partial<
      Omit<
        Post,
        "createdBy" | "createdAt" | "updatedAt" | "images" | "videos" | "likes"
      >
    >
  ): Promise<TypeDefaultResponse & { post?: Post }> {
    return await customKy
      .patch(url(baseURL, posts.updatePost, { id: updateData._id }), {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async getPostFiles(
    token: string,
    postId: string
  ): Promise<GetPostFilesResposne> {
    return await customKy
      .get(url(baseURL, posts.getPostFiles, { id: postId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUnverifiedPosts(
    token: string,
    limit: number,
    page: number
  ): Promise<TypeDefaultResponse & { posts?: Post[] }> {
    return await ky
      .get(url(baseURL, posts.getUnverifiedPosts, { page, limit }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
