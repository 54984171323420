import { network } from "../config";
import { PriceRotation } from "../types/PricingRotation";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { SessionComment, SessionPackage } from "../types/TypeSession";
import {
  Badge,
  Education,
  EducationAccreditation,
  EducationCertificatesResponse,
  EducationCourse,
  Federation,
  FederationsCertificatesResponse,
  MainSpecializations,
  NewCategory,
  OverLaps,
  ParamsAvailable,
  Qualification,
  QualificationCertificatesResponse,
  SpecialistData,
  SpecialistLevel,
  SpecialistTag,
  SpecialistTagsDirections,
  TaxCategory,
  TimeRange,
  TypeSpecialistFilter,
  UserUpdates,
} from "../types/TypeSpecialist";
import { TypeUpdatedUserInfo, TypeUserData } from "../types/TypeUsers";
import url from "../utils/formApiUrl";
import customKy from "./kyExtend";

const { specialists } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "specialists/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SpecialistApi {
  static async update(
    updateData: TypeUpdatedUserInfo,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      specialistData: SpecialistData;
    }
  > {
    return await customKy
      .patch(url(baseURL, specialists.update, { id: updateData._id }), {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async getMainSpecializations(token: string): Promise<
    TypeDefaultResponse & {
      mainSpecializations?: MainSpecializations[];
    }
  > {
    return await customKy
      .get(url(baseURL, specialists.getMainSpecializations), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getFilteredSpecialists(
    token: string,
    payload: {
      userId: string;
      filters: TypeSpecialistFilter;
      alreadyFetched?: string[];
    }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
      users?: TypeUserData[];
      overlapDays: OverLaps[];
    }
  > {
    return await customKy
      .post(url(baseURL, specialists.getFilteredSpecialists), {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getAvailableParams(
    token: string,
    payload: { param: string; filters: TypeSpecialistFilter }
  ): Promise<string[]> {
    return await customKy
      .post(url(baseURL, specialists.getAvailableParams), {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSpecialistTags(
    token: string,
    payload?: {
      parentTagsIds?: string[];
      direction?: SpecialistTagsDirections;
      level?: number;
    }
  ): Promise<TypeDefaultResponse & { tags?: SpecialistTag[] }> {
    return await customKy
      .post(url(baseURL, specialists.getSpecialistTags), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async createEducation(
    token: string,
    payload: {
      educationText: { language: string; text: string }[];
      specialization: string;
    }
  ): Promise<TypeDefaultResponse & { education?: Education }> {
    return await customKy
      .post(url(baseURL, specialists.createEducation), {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async createEducationCourse(
    token: string,
    payload: {
      courseText: { language: string; text: string }[];
      isShown?: boolean;
      educationId: string;
    }
  ): Promise<TypeDefaultResponse & { course?: EducationCourse }> {
    return await customKy
      .post(url(baseURL, specialists.createEducationCourse), {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }
  static async createEducationAccreditation(
    token: string,
    payload: {
      accreditationText: { language: string; text: string }[];
      isShown?: boolean;
      courseId: string;
    }
  ): Promise<TypeDefaultResponse & { accreditation?: EducationAccreditation }> {
    return await customKy
      .post(url(baseURL, specialists.createEducationAccreditation), {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async createFederation(
    token: string,
    payload: {
      federationText: { language: string; text: string }[];
      specialization: string;
      badgeId?: string;
    }
  ): Promise<TypeDefaultResponse & { federation?: Federation }> {
    return await customKy
      .post(url(baseURL, specialists.createFederation), {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async createQualification(
    token: string,
    payload: {
      qualificationText: { language: string; text: string }[];
      specialization: string;
      badgeId?: string;
      federationId: string;
    }
  ): Promise<TypeDefaultResponse & { qualification?: Qualification }> {
    return await customKy
      .post(url(baseURL, specialists.createQualification), {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async createFederationBadge(
    token: string,
    payload: FormData
  ): Promise<TypeDefaultResponse & { badge?: Badge }> {
    return await customKy
      .post(url(baseURL, specialists.createFederationBadge), {
        body: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async updateFederationBadge(
    token: string,
    payload: FormData
  ): Promise<TypeDefaultResponse & { badge?: Badge }> {
    return await customKy
      .post(url(baseURL, specialists.updateFederationBadge), {
        body: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async updateFederation(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { federation?: Federation }> {
    return await customKy
      .post(url(baseURL, specialists.updateFederation), {
        json: { updateData },
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async updateQualification(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { qualification?: Qualification }> {
    return await customKy
      .post(url(baseURL, specialists.updateQualification), {
        json: { updateData },
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async updateEducation(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { education?: Education }> {
    return await customKy
      .post(url(baseURL, specialists.updateEducation), {
        json: { updateData },
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async updateEducationCourse(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { course?: EducationCourse }> {
    return await customKy
      .post(url(baseURL, specialists.updateEducationCourse), {
        json: { updateData },
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async updateEducationAccreditation(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { accreditation?: EducationAccreditation }> {
    return await customKy
      .post(url(baseURL, specialists.updateEducationAccreditation), {
        json: { updateData },
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async getEducationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: EducationCertificatesResponse[];
      total?: number;
    }
  > {
    return await customKy
      .get(
        url(baseURL, specialists.getEducationCertificates, {
          id: specialistId,
          page,
          limit: amount,
        }),
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getEducationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: EducationCertificatesResponse[];
      total?: number;
    }
  > {
    return await customKy
      .get(
        url(baseURL, specialists.getEducationPublicCertificates, {
          id: specialistId,
          page,
          limit: amount,
        }),
        {}
      )
      .then((res) => res.json());
  }

  static async getFederationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: FederationsCertificatesResponse[];
      total?: number;
    }
  > {
    return await customKy
      .get(
        url(baseURL, specialists.getFederationCertificates, {
          id: specialistId,
          page,
          limit: amount,
        }),
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getFederationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: FederationsCertificatesResponse[];
      total?: number;
    }
  > {
    return await customKy
      .get(
        url(baseURL, specialists.getFederationPublicCertificates, {
          id: specialistId,
          page,
          limit: amount,
        }),
        {}
      )
      .then((res) => res.json());
  }

  static async getQualificationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: QualificationCertificatesResponse[];
      total?: number;
    }
  > {
    return await customKy
      .get(
        url(baseURL, specialists.getQualificationCertificates, {
          id: specialistId,
          page,
          limit: amount,
        }),
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getQualificationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: QualificationCertificatesResponse[];
      total?: number;
    }
  > {
    return await customKy
      .get(
        url(baseURL, specialists.getQualificationPublicCertificates, {
          id: specialistId,
          page,
          limit: amount,
        }),
        {}
      )
      .then((res) => res.json());
  }

  static async getAllSpecialistLevels(payload: {
    mainSpecId?: string;
  }): Promise<
    TypeDefaultResponse & {
      levels?: SpecialistLevel[];
    }
  > {
    const params = new URLSearchParams();
    if (payload && payload.mainSpecId) {
      params.append("mainSpecId", payload.mainSpecId);
    }
    return await customKy
      .get(url(baseURL, specialists.getAllSpecialistLevels, {}, params))
      .then((res) => res.json());
  }

  static async getProfileChangeDiff(
    token: string,
    specialistUserId: string
  ): Promise<
    TypeDefaultResponse & {
      changes?: {
        changedValues: UserUpdates;
        changedAt: Date;
      };
    }
  > {
    return await customKy
      .get(
        url(baseURL, specialists.getProfileChangeDiff, {
          id: specialistUserId,
        }),
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getAllEducations(
    token: string,
    payload: {
      filters: {
        specialization?: string[];
        isShown?: boolean;
      };
      page: number;
      limit: number;
    }
  ): Promise<
    TypeDefaultResponse & {
      educations?: Education[];
      amount: number;
    }
  > {
    return await customKy
      .post(url(baseURL, specialists.getAllEducations), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getAllEducationCourses(
    token: string,
    payload: {
      filters: {
        education?: string[];
        isShown?: boolean;
      };
      page: number;
      limit: number;
    }
  ): Promise<
    TypeDefaultResponse & {
      courses?: EducationCourse[];
      amount: number;
    }
  > {
    return await customKy
      .post(url(baseURL, specialists.getAllEducationCourses), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getAllEducationAccreditations(
    token: string,
    payload: {
      filters: {
        educationCourse?: string[];
        isShown?: boolean;
      };
      page: number;
      limit: number;
    }
  ): Promise<
    TypeDefaultResponse & {
      accreditations?: EducationAccreditation[];
      amount: number;
    }
  > {
    return await customKy
      .post(url(baseURL, specialists.getAllEducationAccreditations), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getAllFederations(
    token: string,
    payload: {
      filters: {
        specialization?: string[];
        isShown?: boolean;
      };
      page: number;
      limit: number;
    }
  ): Promise<
    TypeDefaultResponse & {
      federations?: Federation[];
      amount: number;
    }
  > {
    return await customKy
      .post(url(baseURL, specialists.getAllFederations), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getAllQualifications(
    token: string,
    payload: {
      filters: {
        specialization?: string[];
        isShown?: boolean;
      };
      page: number;
      limit: number;
    }
  ): Promise<
    TypeDefaultResponse & {
      qualifications?: Qualification[];
      amount: number;
    }
  > {
    return await customKy
      .post(url(baseURL, specialists.getAllQualifications), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async createTaxCategory(
    token: string,
    newCategory: NewCategory
  ): Promise<TypeDefaultResponse & { category?: TaxCategory }> {
    return await customKy
      .post(url(baseURL, specialists.createTaxCategory), {
        json: { newCategory },
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async deleteTaxCategory(
    token: string,
    categoryId: string
  ): Promise<TypeDefaultResponse & { category?: TaxCategory }> {
    return await customKy
      .post(url(baseURL, specialists.deleteTaxCategory), {
        json: { categoryId },
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async updateTaxCategory(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { category?: TaxCategory }> {
    return await customKy
      .post(url(baseURL, specialists.updateTaxCategory), {
        json: { updateData },
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async getTaxCategories(
    token: string,
    mainSpecializationId?: string
  ): Promise<
    TypeDefaultResponse & {
      categories?: TaxCategory[];
    }
  > {
    const params = new URLSearchParams();
    if (mainSpecializationId) {
      params.append("mainSpecializationId", mainSpecializationId);
    }
    return await customKy
      .get(url(baseURL, specialists.getTaxCategories, {}, params), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async setSpecialistTaxCategory(
    token: string,
    payload: {
      specialistId: string;
      categoryId?: string;
    }
  ): Promise<TypeDefaultResponse & { category?: TaxCategory }> {
    return await customKy
      .put(url(baseURL, specialists.setSpecialistTaxCategory), {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async addNextLevelPriceRotation(
    token: string,
    formData: FormData
  ): Promise<TypeDefaultResponse & { priceRotation?: PriceRotation }> {
    return await customKy
      .put(url(baseURL, specialists.addNextLevelPriceRotation), {
        body: formData,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async getNextPriceRotation(token: string): Promise<
    TypeDefaultResponse & {
      priceRotation?: PriceRotation;
    }
  > {
    return await customKy
      .get(url(baseURL, specialists.getNextPriceRotation), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getCurrentPriceRotation(token: string): Promise<
    TypeDefaultResponse & {
      priceRotation?: PriceRotation;
    }
  > {
    return await customKy
      .get(url(baseURL, specialists.getCurrentPriceRotation), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSpecialistsComments(
    userId: string,
    limit: number,
    page: number,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      comments: SessionComment[];
      totalAmount: number;
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }

    return await customKy
      .get(
        url(
          baseURL,
          specialists.getSpecialistsComments,
          { id: userId, page, limit },
          params
        )
      )
      .then((res) => res.json());
  }

  static async getSpecialistsSessionsAmount(
    userId: string,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      amount: number;
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }
    return await customKy
      .get(
        url(
          baseURL,
          specialists.getSpecialistsSessionsAmount,
          { id: userId },
          params
        )
      )
      .then((res) => res.json());
  }

  static async getSpecialistSessionPackages(
    token: string,
    specialistUserId: string,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      packages?: SessionPackage[] | null;
      overlapDays?: {
        specialist: string;
        overlaps: { day: string; overlapTime: TimeRange[] }[];
      }[];
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }
    return await customKy
      .get(
        url(
          baseURL,
          specialists.getSpecialistSessionPackages,
          { id: specialistUserId },
          params
        ),
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }
}
