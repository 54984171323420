import ky from "ky";
import { network } from "../config";
import {
  Company,
  Project,
  Roles,
  TypeCompanyEmployee,
  TypeCompanyStatistic,
  TypeNewCompany,
} from "../types/Company";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { Sessions } from "../types/TypeSession";
import { TypeUpdatedUserInfo, TypeUserData } from "../types/TypeUsers";
import url from "../utils/formApiUrl";

const { companies } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "companies/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class CompanyApi {
  static async createCompany(
    token: string,
    payload: { userId: string; ownerId: string; newCompany: TypeNewCompany }
  ): Promise<TypeDefaultResponse & { company: Company }> {
    return await ky
      .post(url(baseURL, companies.createCompany), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async updateCompany(
    token: string,
    payload: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { company: Company }> {
    return await ky
      .patch(url(baseURL, companies.update, { id: payload._id }), {
        headers: getAuthHeader(token),
        json: { payload },
      })
      .then((res) => res.json());
  }

  static async deleteCompany(
    token: string,
    payload: { comapnyId: string }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .delete(url(baseURL, companies.deleteCompany), {
        headers: getAuthHeader(token),
        json: { payload },
      })
      .then((res) => res.json());
  }

  static async getCompanyById(
    token: string,
    companyId: string
  ): Promise<TypeDefaultResponse & { company: Company }> {
    return await ky
      .get(url(baseURL, companies.getCompanyById, { id: companyId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getCompanyParticipants(
    token: string,
    companyId: string
  ): Promise<
    TypeDefaultResponse & { participants: TypeCompanyEmployee[] | null }
  > {
    return await ky
      .get(url(baseURL, companies.getCompanyParticipants, { id: companyId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async joinCompany(
    token: string,
    payload: {
      userId: string;
      code: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(url(baseURL, companies.joinCompany), {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async removeUserFromCompany(
    token: string,
    payload: {
      userIdToRemove: string;
      companyId: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      newNotify?: {
        title: string;
        date: Date;
        for: string;
      } | null;
      emailMesage?: {
        email: string;
        companyName: string;
      };
    }
  > {
    return await ky
      .delete(
        url(baseURL, companies.removeUserFromCompany, {
          id: payload.companyId,
        }),
        {
          headers: getAuthHeader(token),
          json: payload,
        }
      )
      .then((res) => res.json());
  }

  static async inviteUserToCompany(
    token: string,
    payload: {
      emails: string[];
      companyId: string;
      role: Roles.DEEFAULT_USER | Roles.MANAGER | Roles.SPECIALIST;
    }
  ): Promise<
    TypeDefaultResponse & {
      newNotify?: {
        title: string;
        date: Date;
        for: string;
      } | null;
      companyInvite?: {
        email: string;
        link: string;
        companyName: string;
      };
    }
  > {
    return await ky
      .post(
        url(baseURL, companies.inviteUserToCompany, { id: payload.companyId }),
        {
          headers: getAuthHeader(token),
          json: payload,
        }
      )
      .then((res) => res.json());
  }

  static async getCompanySessions(
    token: string,
    companyId: string
  ): Promise<TypeDefaultResponse & { sessions: Sessions[] | null }> {
    return await ky
      .get(url(baseURL, companies.getCompanySessions, { id: companyId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getCompanyStatistic(
    token: string,
    companyId: string
  ): Promise<
    TypeDefaultResponse & { statistics: TypeCompanyStatistic | null }
  > {
    return await ky
      .get(url(baseURL, companies.getCompanyStatistic, { id: companyId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getCompanyProjects(
    token: string,
    companyId: string
  ): Promise<TypeDefaultResponse & { projects: Project[] | null }> {
    return await ky
      .get(url(baseURL, companies.getCompanyProjects, { id: companyId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async inviteBulkUsersToCompany(
    token: string,
    payload: {
      emailsList: string;
      companyId: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      incorrectRoleLines: number[];
      incorrectEmailLines: number[];
      duplicateLines: number[][];
    }
  > {
    return await ky
      .post(
        url(baseURL, companies.inviteBulkUsersToCompany, {
          id: payload.companyId,
        }),
        {
          headers: getAuthHeader(token),
          json: payload,
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }

  static async getAllCompanies(
    token: string,
    page: number,
    limit: number,
    companyName?: string
  ): Promise<
    TypeDefaultResponse & { companies?: Company[] | null; totalAmount: number }
  > {
    const params = new URLSearchParams();
    if (companyName && companyName.length) {
      params.append("companyName", companyName);
    }

    return await ky
      .get(
        url(
          baseURL,
          companies.getAllCompanies,
          {
            page,
            limit,
          },
          params
        ),
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getCompanySpecialists(
    token: string,
    companyId: string
  ): Promise<TypeDefaultResponse & { specialists: TypeUserData[] }> {
    return await ky
      .get(url(baseURL, companies.getCompanySpecialists, { id: companyId }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
