import SpecialistApi from "../../api/specialistsApi";
import Notify from "../../utils/toaster";

type CertificatesPaylaod = {
  filters: {
    specialization?: string[];
    isShown?: boolean;
  };
  page: number;
  limit: number;
};

export const getAllEducations = async (
  token: string | null,
  payload: CertificatesPaylaod
) => {
  if (!token) return;

  const response = await SpecialistApi.getAllEducations(token, payload);

  return response;
};

export const getAllFederations = async (
  token: string | null,
  payload: CertificatesPaylaod
) => {
  if (!token) return;
  const response = await SpecialistApi.getAllFederations(token, payload);
  if (!response.status && response.message) {
    return Notify(response.message);
  }
  return response;
};

export const getAllQualifications = async (
  token: string | null,
  payload: CertificatesPaylaod
) => {
  if (!token) return;
  const response = await SpecialistApi.getAllQualifications(token, payload);
  if (!response.status && response.message) {
    return Notify(response.message);
  }
  return response;
};
